import React, {FC, Fragment, useMemo, useRef, useState} from "react";
import {GetEventPlayersCall} from "../../../apis/player";
import {BookmakerData} from "../../../@types/response";
import {useSelector} from "react-redux";
import {getBookmakers} from "../../../store/selectors/getBookmakers";
import {ENABLED_BOOKMAKERS} from "../../../constants/PlayerConstants";

interface TooltipPlayerOddsProps {
    children: any
    eventId: number
    playerId: string
    marketId: string
    signId: string
    sbv: string
}

const TooltipPlayerOdds: FC<TooltipPlayerOddsProps> = ({children, eventId, playerId, marketId, signId, sbv}) => {

    const bookmakers: BookmakerData = useSelector(getBookmakers);

    const [odds, setOdds] = useState<any>({});
    const [showTooltip, setShowTooltip] = useState<boolean>(false);
    const [tooltipPosition, setTooltipPosition] = useState<{ top: number; left: number }>({top: 0, left: 0});

    const eventBookmakers: number[] = useMemo(() => {
        return Object.entries(bookmakers)
                .filter(([id, bookmaker]) => ENABLED_BOOKMAKERS.has(parseInt(id)) && !bookmaker.isComparison)
                .map(([b]) => parseInt(b));
    }, [bookmakers]);

    const tipRef = useRef<HTMLDivElement>(null);

    const getOdds = () => {
        GetEventPlayersCall(eventId).then(
                (result) => {
                    setOdds(result);
                },
                () => {
                    setOdds({});
                }
        );
    };

    const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
        getOdds();
        setShowTooltip(true);
        updateTooltipPosition(e);
    };

    const handleMouseLeave = () => {
        setShowTooltip(false);
    };

    const updateTooltipPosition = (e: React.MouseEvent<HTMLDivElement>) => {
        const tooltipWidth = tipRef.current?.offsetWidth || 0;
        const tooltipHeight = tipRef.current?.offsetHeight || 0;

        const maxX = window.innerWidth - tooltipWidth - 10;
        const maxY = window.innerHeight - tooltipHeight - 10;

        const newLeft = Math.min(e.clientX + 10, maxX);
        const newTop = Math.min(e.clientY + 10, maxY);

        setTooltipPosition({top: newTop, left: newLeft});
    };

    return (
            <Fragment>
                {showTooltip && (
                        <div
                                className="absolute whitespace-no-wrap border-2 bg-eventDetail-primary text-white px-4 py-2 rounded-xl flex items-center transition-none"
                                style={{
                                    position: 'fixed',
                                    top: tooltipPosition.top,
                                    left: tooltipPosition.left,
                                    zIndex: 9999,
                                }}
                                ref={tipRef}
                        >
                            <table className="w-full border-monitor-primary border-spacing-1">
                                <tbody>
                                {eventBookmakers.map((bookmakerId) => {
                                    const bookmaker = bookmakers[bookmakerId];
                                    const backOdd = odds[bookmaker.id]?.players[playerId]?.markets[marketId.toString()]?.[sbv]?.[signId]?.odd;
                                    return (
                                            <tr key={bookmakerId} className="border-4 border-monitor-primary mb-2">
                                                <td
                                                        className={`text-left font-bold border-eventDetail-primary ${bookmakers[bookmakerId].id === 2 ? 'bg-monitor-betfair' : 'bg-eventDetail-bookmaker'} px-2 pr-4`}
                                                >
                                                    {bookmakers[bookmakerId].name}
                                                </td>
                                                <td
                                                        className={`text-center border-eventDetail-primary ${bookmakers[bookmakerId].id === 2 ? 'bg-monitor-betfair' : 'bg-eventDetail-bookmaker'} px-2 pl-4`}
                                                >
                                                    <b>{backOdd > 1 ? backOdd : '-'}</b>
                                                </td>
                                            </tr>
                                    );
                                })}
                                </tbody>
                            </table>
                        </div>
                )}

                <div
                        className="text-left"
                        onMouseEnter={handleMouseEnter}
                        onMouseMove={updateTooltipPosition}
                        onMouseLeave={handleMouseLeave}
                >
                    {children}
                </div>
            </Fragment>
    );
};

export default TooltipPlayerOdds;
