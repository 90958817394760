import {ExtraMarketData, MarketData, MoneyLineData, OutcomeObject, PlayerMarketData} from "../@types/response";

const PREFIX = 'COMMON'

enum Constants {
    SET_BOOKMAKERS,
    GET_SPORTS,
    GET_SPORTS_SUCCESS,
    GET_SPORTS_ERROR,
    SET_ALIVE,

    GET_MARKET_CAP,
    GET_MARKET_CAP_SUCCESS,
    SET_MARKET_CAP,

    GET_EVENT_MONEY_LINE,
    GET_EVENT_MONEY_LINE_SUCCESS,
    SET_EVENT_MONEY_LINE,
}

export const SPORT_NAME: { [sportId: string]: string } = {
    '1': 'Soccer',
    '2': 'Basketball',
    '5': 'Tennis'
}

export enum Product {
    MONITOR = 'monitor',
    MARKET_PLUS = 'market_plus',
    PLAYER = 'player',
    DROPPING_ODDS = "dropping_odds",
    HOT_MATCH = "hot_match",
    EXTRA = "extra"
}

export enum MarginFilter {
    HIDDEN = -1,
    DISABLED = 0,
    ENABLED = 1
}

export enum MarginMethod {
    NEW,
    ORIGINAL
}

export const HEARTBEAT_PRODUCTS = [Product.MONITOR, Product.MARKET_PLUS, Product.PLAYER]

export const SET_BOOKMAKERS = PREFIX + '_' + Constants.SET_BOOKMAKERS;

export const GET_SPORTS = PREFIX + '_' + Constants.GET_SPORTS;
export const GET_SPORTS_SUCCESS = PREFIX + '_' + Constants.GET_SPORTS_SUCCESS;
export const GET_SPORTS_ERROR = PREFIX + '_' + Constants.GET_SPORTS_ERROR;

export const SET_ALIVE = PREFIX + '_' + Constants.SET_ALIVE;

export const GET_MARKET_CAP = PREFIX + '_' + Constants.GET_MARKET_CAP;
export const GET_MARKET_CAP_SUCCESS = PREFIX + '_' + Constants.GET_MARKET_CAP_SUCCESS;
export const SET_MARKET_CAP = PREFIX + '_' + Constants.SET_MARKET_CAP;

export const GET_EVENT_MONEY_LINE = PREFIX + '_' + Constants.GET_EVENT_MONEY_LINE;
export const GET_EVENT_MONEY_LINE_SUCCESS = PREFIX + '_' + Constants.GET_EVENT_MONEY_LINE_SUCCESS;
export const SET_EVENT_MONEY_LINE = PREFIX + '_' + Constants.SET_EVENT_MONEY_LINE;


export const AVERAGE = 0;
export const AVERAGE_2 = -1;
export const BETFAIR = 2;
export const PINNACLE = 3;

export const COMPARISONS = [BETFAIR, PINNACLE]
export const COMPARISONS_PRIORITY = {[BETFAIR.toString()]: 0, [PINNACLE.toString()]: 1, [AVERAGE.toString()]: 2}

export const MARGIN_LIMIT = 10;

export const MARKETS: MarketData = {
    "1": {
        id: 1,
        name: "1X2",
        outcomes: {"1": {name: "1"}, "2": {name: "X"}, "3": {name: "2"}},
        hasSbvs: false,
        sport: 1
    },
    "2": {
        id: 2,
        name: "O/U",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: false,
        sport: 1
    },
    "3": {
        id: 3,
        name: "H/H",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        hasSbvs: false,
        sport: 2
    },
    "4": {
        id: 4,
        name: "H/H",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        hasSbvs: false,
        sport: 5
    },
    "9": {
        id: 9,
        name: "BTTS",
        outcomes: {"2": {name: "Yes"}, "3": {name: "No"}},
        hasSbvs: false,
        sport: 1
    },

    "11": {
        id: 11,
        name: "O/U 1.5",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: false,
        sport: 1
    },
    "12": {
        id: 12,
        name: "O/U 3.5",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: false,
        sport: 1
    },
    "13": {
        id: 13,
        name: "O/U 4.5",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: false,
        sport: 1
    },
    "14": {
        id: 14,
        name: "1X2 HT",
        outcomes: {"1": {name: "1"}, "2": {name: "X"}, "3": {name: "2"}},
        hasSbvs: false,
        sport: 1
    },
    "15": {
        id: 15,
        name: "O/U 1.5 HT",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: false,
        sport: 1
    },
    "16": {
        id: 16,
        name: "TT SET 1",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        hasSbvs: false,
        sport: 5
    },
    "17": {
        id: 17,
        name: "O/U GAME",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: true,
        sport: 5
    },
    "18": {
        id: 18,
        name: "T/T HAND. GAME",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        hasSbvs: true,
        sport: 5
    },
    "19": {
        id: 19,
        name: "SET BETTING",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: false,
        sport: 5
    },
    "20": {
        id: 20,
        name: "T/T HAND.",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        hasSbvs: true,
        sport: 2
    },
    "21": {
        id: 21,
        name: "O/U (INCL. TS)",
        outcomes: {"2": {name: "Over"}, "3": {name: "Under"}},
        hasSbvs: true,
        sport: 2
    },
    "22": {
        id: 22,
        name: "Set Bet",
        outcomes: {},
        hasSbvs: true,
        sport: 2
    }
}

export const PLAYER_MARKETS: PlayerMarketData = {
    "Goalscorers": {
        id: "Goalscorers",
        name: "Goalscorers",
        outcomes: {
            "Any": {name: "Anytime"},
            "1st": {name: "First"},
            "2o+": {name: "2orMore"},
            "3o+": {name: "3orMore"}
        }
    },
    "Halves": {id: "Halves", name: "Halves", outcomes: {"AnyHT": {name: "AnyHT"}, "BHlv": {name: "BothHalves"}}},
    "Assist/Card": {
        id: "Assist/Card",
        name: "Assist/Card",
        outcomes: {
            "Asst": {name: "Assist"},
            "Cd": {name: "Card"},
            "1stCd": {name: "FirstCard"}
        }
    },
    "FlsR": {id: "FlsR", name: "Fouls Received", outcomes: {"O": {name: "Over"}, "U": {name: "Under"}}, sbv: true},
    "FlsC": {id: "FlsC", name: "Fouls Committed", outcomes: {"O": {name: "Over"}, "U": {name: "Under"}}, sbv: true},
    "Tck": {id: "Tck", name: "Tackles", outcomes: {"O": {name: "Over"}, "U": {name: "Under"}}, sbv: true},
    "Pass": {id: "Pass", name: "Passes", outcomes: {"O": {name: "Over"}, "U": {name: "Under"}}, sbv: true},
    "ShotsOnTarget": {
        id: "ShotsOnTarget",
        name: "Shots On Target",
        outcomes: {
            "1SOT": {name: "1 TP"},
            "2SOT": {name: "2 TP"},
            "3SOT": {name: "3 TP"},
            "4SOT": {name: "4 TP"},
            "5SOT": {name: "5 TP"}
        }
    },
    "TotalShots": {
        id: "TotalShots",
        name: "Total Shots",
        outcomes: {
            "1TS": {name: "1 TT"},
            "2TS": {name: "2 TT"},
            "3TS": {name: "3 TT"},
            "4TS": {name: "4 TT"},
            "5TS": {name: "5 TT"},
            "6TS": {name: "6 TT"},
            "7TS": {name: "7 TT"}
        }
    },
    "Points": {id: "Points", name: "Points", outcomes: {"O": {name: "Over"}, "U": {name: "Under"}}, sbv: true},
    "Assists": {id: "Assists", name: "Assists", outcomes: {"O": {name: "Over"}, "U": {name: "Under"}}, sbv: true},
    "Rebounds": {id: "Rebounds", name: "Rebounds", outcomes: {"O": {name: "Over"}, "U": {name: "Under"}}, sbv: true},
    "3Points": {id: "3Points", name: "3Points", outcomes: {"O": {name: "Over"}, "U": {name: "Under"}}, sbv: true},
    "Steals": {id: "Steals", name: "Steals", outcomes: {"O": {name: "Over"}, "U": {name: "Under"}}, sbv: true},
    "Turnovers": {id: "Turnovers", name: "Turnovers", outcomes: {"O": {name: "Over"}, "U": {name: "Under"}}, sbv: true},
    "Blocks": {id: "Blocks", name: "Blocks", outcomes: {"O": {name: "Over"}, "U": {name: "Under"}}, sbv: true}
}

export const PLAYER_DETAILS_MARKETS: { [sportId: string]: string[] } = {
    1: ["Goalscorers", "Halves", "Assist/Card", "FlsR", "FlsC", "Tck", "Pass", "ShotsOnTarget", "TotalShots"],
    2: ["Points", "Assists", "Rebounds", "3Points", "Steals", "Turnovers", "Blocks"]
}

export const PLAYER_DEFAULTS: { [sportId: string]: { market: string, sign: string } } = {
    1: {market: "Goalscorers", sign: "Any"},
    2: {market: "Points", sign: "O"}
}


export const EXTRA_MARKETS: ExtraMarketData = {
    "1X2CN": {
        id: "1X2CN",
        name: "1X2 Corners",
        outcomes: {"1": {name: "1", priority: 1}, "X": {name: "X", priority: 2}, "2": {name: "2", priority: 3}},
        hasSbvs: false
    },
    "OUCN": {
        id: "OUCN",
        name: "U/O Corners",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OUHCN": {
        id: "OUHCN",
        name: "U/O Corners Home",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OUACN": {
        id: "OUACN",
        name: "U/O Corners Away",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OU1TCN": {
        id: "OU1TCN",
        name: "U/O Corners 1T",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OU2TCN": {
        id: "OU2TCN",
        name: "U/O Corners 2T",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OU1THCN": {
        id: "OU1THCN",
        name: "U/O Corners 1T Home",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OU2THCN": {
        id: "OU2THCN",
        name: "U/O Corners 2T Home",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OU1TACN": {
        id: "OU1TACN",
        name: "U/O Corners 1T Away",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OU2TACN": {
        id: "OU2TACN",
        name: "U/O Corners 2T Away",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "HNDCN": {
        id: "HNDCN",
        name: "H/H Corners Handicap",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        hasSbvs: true
    },
    "HND1TCN": {
        id: "HND1TCN",
        name: "H/H Corners Handicap 1T",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        hasSbvs: true
    },
    "TOTALSCN": {
        id: "TOTALSCN", name: "Total Corners", hasSbvs: false,
        outcomes: {
            "<6": {name: "<6"},
            "6-8": {name: "6-8"},
            "9-11": {name: "9-11"},
            "12-14": {name: "12-14"},
            ">14": {name: ">14"}
        }
    },
    "TOTALSHCN": {
        id: "TOTALSHCN",
        name: "Total Corners Home",
        hasSbvs: false,
        outcomes: {"0-2": {name: "0-2"}, "3-4": {name: "3-4"}, "5-6": {name: "5-6"}, ">=7": {name: ">=7"}}
    },
    "TOTALSACN": {
        id: "TOTALSACN",
        name: "Total Corners Away",
        hasSbvs: false,
        outcomes: {"0-2": {name: "0-2"}, "3-4": {name: "3-4"}, "5-6": {name: "5-6"}, ">=7": {name: ">=7"}}
    },
    "TOTALS1TCN": {
        id: "TOTALS1TCN",
        name: "Total Corners 1T",
        hasSbvs: false,
        outcomes: {"<5": {name: "<5"}, "5-6": {name: "5-6"}, ">6": {name: ">6"}}
    },
    "TOTALS2TCN": {
        id: "TOTALS2TCN",
        name: "Total Corners 2T",
        hasSbvs: false,
        outcomes: {"<5": {name: "<5"}, "5-6": {name: "5-6"}, ">6": {name: ">6"}}
    },
    "TOTALS1THCN": {
        id: "TOTALS1THCN",
        name: "Total Corners 1T Home",
        hasSbvs: false,
        outcomes: {"0-1": {name: "0-1"}, "2": {name: "2"}, "3": {name: "3"}, ">=4": {name: ">=4"}}
    },
    "TOTALS2THCN": {
        id: "TOTALS2THCN",
        name: "Total Corners 2T Home",
        hasSbvs: false,
        outcomes: {"0-1": {name: "0-1"}, "2": {name: "2"}, "3": {name: "3"}, ">=4": {name: ">=4"}}
    },
    "TOTALS1TACN": {
        id: "TOTALS1TACN",
        name: "Total Corners 1T Away",
        hasSbvs: false,
        outcomes: {"0-1": {name: "0-1"}, "2": {name: "2"}, "3": {name: "3"}, ">=4": {name: ">=4"}}
    },
    "TOTALS2TACN": {
        id: "TOTALS2TACN",
        name: "Total Corners 2T Away",
        hasSbvs: false,
        outcomes: {"0-1": {name: "0-1"}, "2": {name: "2"}, "3": {name: "3"}, ">=4": {name: ">=4"}}
    },
    "1X2CD": {
        id: "1X2CD",
        name: "1X2 Cards",
        outcomes: {"1": {name: "1", priority: 1}, "X": {name: "X", priority: 2}, "2": {name: "2", priority: 3}},
        hasSbvs: false
    },
    "OUCD": {
        id: "OUCD",
        name: "U/O Cards",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OUHCD": {
        id: "OUHCD",
        name: "U/O Cards Home",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OUACD": {
        id: "OUACD",
        name: "U/O Cards Away",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OU1TCD": {
        id: "OU1TCD",
        name: "U/O Cards 1T",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OU2TCD": {
        id: "OU2TCD",
        name: "U/O Cards 2T",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OU1THCD": {
        id: "OU1THCD",
        name: "U/O Cards 1T Home",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OU2THCD": {
        id: "OU2THCD",
        name: "U/O Cards 2T Home",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OU1TACD": {
        id: "OU1TACD",
        name: "U/O Cards 1T Away",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OU2TACD": {
        id: "OU2TACD",
        name: "U/O Cards 2T Away",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "HNDCD": {
        id: "HNDCD",
        name: "H/H Cards Handicap",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        hasSbvs: true
    },
    "HND1TCD": {
        id: "HND1TCD",
        name: "H/H Cards Handicap 1T",
        outcomes: {"1": {name: "1"}, "2": {name: "2"}},
        hasSbvs: true
    },
    "TOTALSCD": {
        id: "TOTALSCD",
        name: "Total Cards",
        hasSbvs: false,
        outcomes: {
            "<=3": {name: "<=3"},
            "4": {name: "4"},
            "5": {name: "5"},
            "6": {name: "6"},
            "7": {name: "7"},
            "8": {name: "8"},
            "9": {name: "9"},
            "10": {name: "10"},
            "11": {name: "11"},
            ">=11": {name: ">=11"}
        }
    },
    "TOTALSHCD": {
        id: "TOTALSHCD",
        name: "Total Cards Home",
        hasSbvs: false,
        outcomes: {}
    },
    "TOTALSACD": {
        id: "TOTALSACD",
        name: "Total Cards Away",
        hasSbvs: false,
        outcomes: {}
    },
    "TOTALS1TCD": {
        id: "TOTALS1TCD",
        name: "Total Cards 1T",
        hasSbvs: false,
        outcomes: {}
    },
    "TOTALS2TCD": {
        id: "TOTALS2TCD",
        name: "Total Cards 2T",
        hasSbvs: false,
        outcomes: {}
    },
    "TOTALS1THCD": {
        id: "TOTALS1THCD",
        name: "Total Cards 1T Home",
        hasSbvs: false,
        outcomes: {}
    },
    "TOTALS1TACD": {
        id: "TOTALS1TACD",
        name: "Total Cards 1T Away",
        hasSbvs: false,
        outcomes: {}
    },
    "TOTALS2THCD": {
        id: "TOTALS2THCD",
        name: "Total Cards 2T Home",
        hasSbvs: false,
        outcomes: {}
    },
    "TOTALS2TACD": {
        id: "TOTALS2TACD",
        name: "Total Cards 2T Away",
        hasSbvs: false,
        outcomes: {}
    },
    "1X2TT": {
        id: "1X2TT",
        name: "1X2 Total Shots",
        outcomes: {"1": {name: "1", priority: 1}, "X": {name: "X", priority: 2}, "2": {name: "2", priority: 3}},
        hasSbvs: false
    },
    "OUTT": {
        id: "OUTT",
        name: "U/O Total Shots",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OUHTT": {
        id: "OUHTT",
        name: "U/O Total Shots Home",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OUATT": {
        id: "OUATT",
        name: "U/O Total Shots Away",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "1X2TP": {
        id: "1X2TP",
        name: "1X2 Shots On Target",
        outcomes: {"1": {name: "1", priority: 1}, "X": {name: "X", priority: 2}, "2": {name: "2", priority: 3}},
        hasSbvs: false
    },
    "OUTP": {
        id: "OUTP",
        name: "U/O Shots On Target",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OUHTP": {
        id: "OUHTP",
        name: "U/O Shots On Target Home",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OUATP": {
        id: "OUATP",
        name: "U/O Shots On Target Away",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "1X2OS": {
        id: "1X2OS",
        name: "1X2 Offsides",
        outcomes: {"1": {name: "1", priority: 1}, "X": {name: "X", priority: 2}, "2": {name: "2", priority: 3}},
        hasSbvs: false
    },
    "OUOS": {
        id: "OUOS",
        name: "U/O Offsides",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OUHOS": {
        id: "OUHOS",
        name: "U/O Offsides Home",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OUAOS": {
        id: "OUAOS",
        name: "U/O Offsides Away",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "1X2FC": {
        id: "1X2FC",
        name: "1X2 Fouls Committed",
        outcomes: {"1": {name: "1", priority: 1}, "X": {name: "X", priority: 2}, "2": {name: "2", priority: 3}},
        hasSbvs: false
    },
    "OUFC": {
        id: "OUFC",
        name: "U/O Fouls Committed",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OUHFC": {
        id: "OUHFC",
        name: "U/O Fouls Committed Home",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
    "OUAFC": {
        id: "OUAFC",
        name: "U/O Fouls Committed Away",
        outcomes: {"U": {name: "Under"}, "O": {name: "Over"}},
        hasSbvs: true
    },
}

export const EXTRA_MARKET_GROUPS: { [name: string]: string[] } = {
    "Total Shots": ["1X2TT", "OUTT", "OUHTT", "OUATT"],
    "Shots On Target": ["1X2TP", "OUTP", "OUHTP", "OUATP"],
    "Offsides": ["1X2OS", "OUOS", "OUHOS", "OUAOS"],
    "Fouls Committed": ["1X2FC", "OUFC", "OUHFC", "OUAFC"],
    "Corners": ["1X2CN", "OUCN", "OUHCN", "OUACN", "OU1TCN", "OU2TCN", "OU1THCN", "OU2THCN", "OU1TACN", "OU2TACN", "HNDCN",
        "HND1TCN", "TOTALSCN", "TOTALSHCN", "TOTALSACN", "TOTALS1TCN", "TOTALS2TCN", "TOTALS1THCN", "TOTALS1TACN",
        "TOTALS2THCN", "TOTALS2TACN"],
    "Cards": ["1X2CD", "OUCD", "OUHCD", "OUACD", "OU1TCD", "OU2TCD", "OU1THCD", "OU2THCD", "OU1TACD", "OU2TACD", "HNDCD",
        "HND1TCD", "TOTALSCD", "TOTALSHCD", "TOTALSACD", "TOTALS1TCD", "TOTALS2TCD", "TOTALS1THCD", "TOTALS1TACD", "TOTALS2THCD",
        "TOTALS2TACD"]
}


export const getOutcomesName = (marketId: string, signId: string) => {
    if (marketId.includes('@')) {
        marketId = marketId.split('@')[0]
    }
    const market: OutcomeObject = MARKETS[marketId].outcomes;
    if (!Object.keys(market).length || !market[signId]) return '-'
    return market[signId].name
}

export const getMarketName = (marketId: string) => {
    const market = MARKETS[marketId] || PLAYER_MARKETS[marketId];
    return market?.name ?? "-"
};

export const getMoneyLine = (marketId: number, moneyLine: MoneyLineData): number | null => {
    switch (marketId) {
        case 1:
        case 3:
        case 4:
        case 9:
            return moneyLine.mm
        case 2:
            return moneyLine.mt
    }
    return null
}

