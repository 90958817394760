import {ExtraSettingsObject, OddsObject} from "../../../@types/response";
import {useSelector} from "react-redux";
import {getSettings} from "../../../store/selectors/extra/getSettings";

export default function Odds({
                                 odds,
                                 alert,
                                 playability = 1
                             }: { odds: OddsObject[], alert: any, playability: number }) {

    const settings: ExtraSettingsObject = useSelector(getSettings);

    let best = 'bg-monitor-margin-l2'
    if (alert.margin < settings.comparisons[alert.comparisonType.toString()].level1) {
        best = 'bg-monitor-margin-l0'
    } else if (alert.margin < settings.comparisons[alert.comparisonType.toString()].level0) {
        best = 'bg-monitor-margin-l1'
    }

    return (
            <div className='flex justify-between'>
                {odds.map((odd: OddsObject, idx) => {
                    const style_playability = playability === 1 ? best : 'outline outline-1 outline-offset-8 outline-[#0dde0d] text-[#0dde0d]'
                    return <div
                            key={idx}
                            data-id={`book-odds-${odd.signId}`}
                            data-value={odd.odd.toFixed(2)}
                            className={`p-1 rounded-md mx-1 text-center w-1/${odds.length} ${odd.signId === alert.signId ? ' font-bold cursor-pointer text-[#f5f5dc] ' + best : ''}`}
                    >
                        <div
                                className={odd.signId === alert.signId ? style_playability : ''}
                        >{odd.odd.toFixed(2)}</div>
                    </div>
                })}
            </div>
    )
}
