import React, {FC, RefObject, useEffect} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faChevronDown, faChevronRight, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {
    DropDownItemProps,
    DropDownLabelProps, DropDownMultipleItemProps,
    DropDownProps,
    DropDownSettingItemProps,
    DropDownSportsProps,
} from "../../../@types/inputs";
import RangeBar from "../common/RangeBar";
import {TreeSport} from "./TreeView";
import {getSports} from "../../../store/selectors/getSports";
import {useSelector} from "react-redux";
import {MarginFilter, MarginMethod, SPORT_NAME} from "../../../constants/CommonConstants";
import {BookmakerData, SportsFilter, User} from "../../../@types/response";
import getClient from "../../../store/selectors/getClient";
import {getBookmakers} from "../../../store/selectors/getBookmakers";
import {ENABLED_BOOKMAKERS} from "../../../constants/PlayerConstants";
import {ENABLED_BOOKMAKERS as EXTRA_ENABLED_BOOKMAKERS} from "../../../constants/ExtraConstants";

export const DropDownSettingItem: FC<DropDownSettingItemProps> = ({
                                                                      label,
                                                                      settings,
                                                                      onChange,
                                                                      maxLimit,
                                                                      minLimit,
                                                                      margin = MarginFilter.ENABLED,
                                                                      hasBookmakers = false,
                                                                      hasAmountMarket = false,
                                                                      hasAmountOutcome = false,
                                                                      marginPercentage = false,
                                                                      isPlayer = false,
                                                                      isExtra = false,
                                                                  }) => {

    const allBookmakers: BookmakerData = useSelector(getBookmakers);
    const client: User = useSelector(getClient) as User

    const userBookmakersAverage: number[] = client.averages?.filter(b => isPlayer ? ENABLED_BOOKMAKERS.has(b) : isExtra ? EXTRA_ENABLED_BOOKMAKERS.has(b) : true) || []

    const [pending, setPending] = React.useState(false);

    const [min, setMin] = React.useState(settings.level0);
    const [max, setMax] = React.useState(settings.level1);
    const [oddMargin, setOddMargin] = React.useState<null | number>(settings.margin);
    const [method, setMethod] = React.useState<null | number>(settings.method);
    const [amountMarket, setAmountMarket] = React.useState(settings.amountMarket);
    const [amountOutcome, setAmountOutcome] = React.useState(settings.amountOutcome);
    const [bookmakers, setBookmakers] = React.useState<number[]>(settings.bookmakers ||
            Object.values(allBookmakers)
                    .filter(b => userBookmakersAverage.includes(b.id) && !b.isComparison)
                    .filter(b => isPlayer ? ENABLED_BOOKMAKERS.has(b.id) : isExtra ? EXTRA_ENABLED_BOOKMAKERS.has(b.id) : true)
                    .map(b => b.id))
    const [threshold, setThreshold] = React.useState(settings.threshold);

    return (
            <div className="px-3 py-2 w-[26rem]">
                <div className="border border-1.5 rounded-md border-gray-300 p-3">
                    <div className="flex text-gray-700 font-medium">
                        <button
                                type="button"
                                className={"text-sm py-1 px-4 font-normal block w-full whitespace-nowrap"}
                                onClick={() => {
                                    onChange({
                                        status: !settings.status,
                                        margin: oddMargin,
                                        method: method,
                                        threshold: threshold,
                                        level0: min,
                                        level1: max,
                                        amountMarket: amountMarket,
                                        amountOutcome: amountOutcome,
                                        bookmakers: bookmakers
                                    });
                                    setPending(false);
                                }}
                        >
                            <label className="flex items-center cursor-pointer">
                                <div className="relative">
                                    <div className="w-10 h-4 bg-gray-400 rounded-full shadow-inner"/>
                                    <div
                                            className="dot absolute w-6 h-6 bg-white rounded-full shadow -left-1 -top-1 transition"
                                            style={
                                                settings.status
                                                        ? {
                                                            transform: "translateX(100%)",
                                                            backgroundColor: "#48bb78",
                                                        }
                                                        : undefined
                                            }
                                    />
                                </div>
                                <div className="ml-3 text-lg text-black font-bold">{label}</div>
                            </label>
                        </button>
                        <button
                                className={`peer w-32 bg-white border border-1.5 rounded-md border-gray-300 p-1 cursor-pointer
                ${pending ? "border-yellow-400 text-yellow-400" : ""}`}
                                onClick={() => {
                                    onChange({
                                        status: settings.status,
                                        margin: oddMargin,
                                        method: method,
                                        threshold: threshold,
                                        level0: min,
                                        level1: max,
                                        amountMarket: amountMarket,
                                        amountOutcome: amountOutcome,
                                        bookmakers: bookmakers
                                    });
                                    setPending(false);
                                }}
                        >
                            <FontAwesomeIcon icon={faCheck}/>
                            {pending && <span className="text-sm px-2">Save</span>}
                        </button>
                    </div>
                    <div className="flex mt-1">
                        {margin === MarginFilter.ENABLED && <div className="flex w-1/2 my-1">
                            <div className="w-3/5">
                                <label className="inline-flex">
                                    <input
                                            type="checkbox"
                                            className="h-5 w-5"
                                            checked={oddMargin !== null}
                                            onChange={() => {
                                                setOddMargin(oddMargin !== null ? null : 0);
                                                setPending(true);
                                            }}
                                    />
                                    <span className="mx-2">Margin</span>
                                </label>
                            </div>
                            <div className='border border-1.5 rounded-md px-1 mx-2 flex bg-white'>
                                <input
                                        type="number"
                                        className="w-5/6 px-2 text-gray-900 placeholder-transparent focus:outline-none"
                                        disabled={oddMargin === null}
                                        value={oddMargin !== null ? oddMargin : ""}
                                        onChange={(e) => {
                                            const value = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : 0;
                                            setPending(true);
                                            setOddMargin(value);
                                        }}
                                />
                                <p> % </p>
                            </div>
                        </div>}
                        {hasBookmakers && userBookmakersAverage.length && <div className="flex w-1/2 my-1">
                            <div className="w-full">
                                <Dropdown label='Bookmakers' color='defaultRight' inComparison={true}>
                                    {userBookmakersAverage
                                            .sort((a, b) => allBookmakers[b.toString()].name > allBookmakers[a.toString()].name ? -1 : 1)
                                            .map((bookmaker) => {
                                                const visible = bookmakers.includes(bookmaker)
                                                return <DropDownItem
                                                        key={allBookmakers[bookmaker].name}
                                                        label={allBookmakers[bookmaker].name}
                                                        selected={visible}
                                                        onClick={() => {
                                                            if (!visible) {
                                                                setBookmakers(bookmakers.concat(bookmaker))
                                                            } else {
                                                                setBookmakers(bookmakers.filter(b => b !== bookmaker))
                                                            }
                                                            setPending(true);
                                                        }}
                                                />
                                            })}
                                </Dropdown>
                            </div>
                        </div>}
                    </div>
                    <hr className="m-4"/>
                    <div className="flex mt-1">
                        <div className="flex w-1/2">
                            <div className="w-1/2">Threshold</div>
                            <div className="w-1/2 bg-white border border-1.5 rounded-md px-1 mx-1 flex">
                                <input
                                        type="number"
                                        className={`${marginPercentage ? 'w-5/6' : 'w-full'} px-2 text-gray-900 placeholder-transparent focus:outline-none`}
                                        value={threshold}
                                        max={maxLimit}
                                        min={minLimit}
                                        onChange={(e) => {
                                            const value = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : 0;
                                            setPending(true);
                                            setThreshold(value);
                                        }}
                                />
                                {marginPercentage && <p> % </p>}
                            </div>
                        </div>
                        <div className="flex w-3/5">
                            {hasAmountMarket && (<>
                                <div className="w-2/3 ml-1">Min Market</div>
                                <div className="w-1/2 bg-white border border-1.5 rounded-md px-1 mx-1 flex">
                                    <input
                                            type="number"
                                            min={0}
                                            step={50}
                                            className="w-5/6 px-1 text-gray-900 placeholder-transparent focus:outline-none"
                                            value={amountMarket}
                                            onChange={(e) => {
                                                const value = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : 0;
                                                setPending(true);
                                                setAmountMarket(value);
                                            }}
                                    />
                                    <p> € </p>
                                </div>
                            </>)}
                        </div>
                    </div>
                    <div className="flex mt-1">
                        <div className="flex w-1/2">
                            <div className="w-1/2">
                                <label className="inline-flex">
                                    <span>Method</span>
                                </label>
                            </div>
                            <select
                                    className='w-1/2 bg-white border border-1.5 rounded-md mx-1 overflow-hidden'
                                    onChange={(e) => {
                                        setMethod(parseInt(e.target.value));
                                        setPending(true);
                                    }}
                                    disabled={method === null}
                                    value={method !== null ? method : ''}
                            >
                                <option value={MarginMethod.ORIGINAL}>original</option>
                                <option value={MarginMethod.NEW}>new</option>
                            </select>
                        </div>
                        <div className="flex w-3/5">
                            {hasAmountOutcome && (<>
                                <div className="w-2/3 ml-1">Min Outcome</div>
                                <div className="w-1/2 bg-white border border-1.5 rounded-md px-1 mx-1 flex">
                                    <input
                                            type="number"
                                            min={0}
                                            step={50}
                                            className="w-5/6 px-1 text-gray-900 placeholder-transparent focus:outline-none"
                                            value={amountOutcome}
                                            onChange={(e) => {
                                                const value = !isNaN(parseFloat(e.target.value)) ? parseFloat(e.target.value) : 0;
                                                setPending(true);
                                                setAmountOutcome(value);
                                            }}
                                    />
                                    <p> € </p>
                                </div>
                            </>)}
                        </div>
                    </div>
                    { !isPlayer &&
                    <RangeBar
                            min={minLimit * 10}
                            max={maxLimit * 10}
                            defaultMin={min * 10}
                            defaultMax={max * 10}
                            onChange={(_min: number, _max: number) => {
                                if (_min === min && _max === max) return;
                                setMin(_min);
                                setMax(_max);
                                setPending(true);
                            }}
                    />}
                </div>
            </div>
    );
};

export const DropDownItem: FC<DropDownItemProps> = ({
                                                        onClick,
                                                        label,
                                                        selected,
                                                        isTree = false,
                                                        open = false,
                                                        level = 0,
                                                        onOpenClick,
                                                        half = false
                                                    }) => {
    return <div
            className={`2xl:text-monitorBase text-monitorSm py-2 pr-4 ${level === 0 ? 'pl-6' : level === 1 ? 'pl-10' : 'pl-14'} font-normal block w-full whitespace-nowrap`}
            onClick={(e) => {
                e.preventDefault();
                if (!isTree) {
                    onClick();
                } else {
                    onOpenClick();
                }
            }}
    >
        <label className="flex items-center cursor-pointer">
            {isTree && <div className='absolute'>
                <button>
                    <FontAwesomeIcon
                            className={'text-gray-300 text-xs relative right-4 transition-transform duration-500 transform ' + (open ? 'rotate-90' : 'rotate-0')}
                            icon={faChevronRight}/>
                </button>
            </div>
            }
            <div className="relative"
                 onClick={(e) => {
                     if (isTree) {
                         e.preventDefault();
                         e.stopPropagation();
                         onClick();
                     }
                 }}>
                <div className="2xl:w-10 w-7 2xl:h-5 h-4 border-2 border-dropDown-buttonBorder rounded-full shadow-inner"/>
                <div
                        className="dot absolute 2xl:w-3 2xl:h-3 w-2 h-2 bg-dropDown-buttonBorder rounded-full shadow left-1 bottom-1 transition"
                        style={
                            selected
                                    ? {
                                        transform: "translateX(160%) ",
                                        backgroundColor: "#2A2A2A",
                                    }
                                    : half ? {
                                        transform: "translateX(80%) ",
                                        backgroundColor: "#3A3A3A",
                                    } : undefined
                        }
                />
            </div>
            <div className="ml-2 text-white font-bold">{label}</div>
        </label>
    </div>;
};

export const DropDownActions: FC<DropDownLabelProps> = ({onClick, label, children, isNetwork}) => {
    const networkStyle = isNetwork ? "px-4 2xl:w-52 w-36" : "";
    return (
            <div
                    className={
                            "py-2 2xl:text-monitorBase text-monitorSm font-normal flex w-full whitespace-nowrap hover:bg-blue-400 cursor-pointer " +
                            networkStyle
                    }
            >
                <div className="w-full ml-3 text-dropDown-text font-medium flex content-between">
                <span
                        onClick={(e) => {
                            e.preventDefault();
                            onClick(label);
                        }}
                >
                    {label}
                </span>
                    <span style={{flex: "1 1 auto"}}/>
                    <div>{children}</div>
                </div>
            </div>
    );
};

const useWindowEventListener = (event: any, callback: any) => {
    useEffect(() => {
        window.addEventListener(event, callback);
        return () => window.removeEventListener(event, callback);
    }, [event, callback]);
};

export const Dropdown: FC<DropDownProps> = ({label, color, children, isMaxHeight = false, inComparison = false}) => {
    // dropdown props
    const [dropdownShow, setDropdownShow] = React.useState(false);
    const btnDropdownRef: RefObject<HTMLButtonElement> = React.createRef();
    const popoverDropdownRef: RefObject<HTMLDivElement> = React.createRef();

    useWindowEventListener("mousedown", (event: { target: any }) => {
        if (btnDropdownRef.current && btnDropdownRef.current.contains(event.target)) {
            return;
        } else if (popoverDropdownRef.current && !popoverDropdownRef.current.contains(event.target)) {
            setDropdownShow(false);
        }
    });
    // bg colors
    let bgColor;
    let position;
    if (color === "default") {
        bgColor = "bg-toolbar-bgMain";
        position = "-left-7 2xl:top-11 top-10";
    } else if (color === "defaultRight") {
        bgColor = "bg-toolbar-bgMain";
        position = "-left-20 2xl:top-11 top-10";
    } else {
        bgColor = "2xl:w-5 2xl:h-5 w-3 h-3 " + color;
        position = "right-0 2xl:top-10 top-9";
    }
    return (
            <div className="flex flex-wrap ">
                <div className="relative inline-flex align-middle w-full ">
                    <div
                            onClick={() => setDropdownShow(!dropdownShow)}
                            className="flex flex-col items-center cursor-pointer"
                    >
                        <button
                                className={
                                    (inComparison ? 'mx-4 px-4' : "flex uppercase items-center justify-center tpeer 'text-[8px] text-white focus:outline-none rounded-full " +
                                            bgColor)
                                }
                                type="button"
                                ref={btnDropdownRef}
                        >
                            {label}
                        </button>
                        {color === "default" || color === "defaultRight" ? (
                                <FontAwesomeIcon className="text-white" icon={faChevronDown} fontSize={8}/>
                        ) : null}
                    </div>

                    {dropdownShow && (
                            <div
                                    // ref={popoverDropdownRef}
                                    className={
                                            (inComparison ? "bg-gray-500 " : "bg-dropDown-bgMain ") + "absolute top-10 z-50 py-2 list-none text-left shadow-lg overflow-y-scroll dropdown-hidden-scrollbar min-w-fit " + (!isMaxHeight ? inComparison ? '2xl:h-32 h-32' : "2xl:h-52 h-44 " : " ") +
                                            position
                                    }
                                    ref={popoverDropdownRef}
                            >
                                {children}
                            </div>
                    )}
                </div>
            </div>
    );
};

export const DropDownSports: FC<DropDownSportsProps> = ({onFilterChange, sportsFilter}) => {
    const sports = useSelector(getSports);
    return (
            <div className="w-64 text-white">
                {Object.keys(sports).map((sportId) => (
                        <li key={`sport-${sportId}`}>
                            <TreeSport
                                    sportId={sportId}
                                    sportName={SPORT_NAME[sportId.toString()] ?? "Soccer"}
                                    categories={sports[sportId]}
                                    selection={sportsFilter[sportId.toString()] ?? {}}
                                    onSportChange={(_selection: SportsFilter) =>
                                            onFilterChange(Object.assign({}, sportsFilter, _selection))
                                    }
                            />
                        </li>
                ))}
            </div>
    );
};

export const InstantDropdown: FC<DropDownProps> = ({label, color, children}) => {
    // dropdown props
    const [dropdownShow, setDropdownShow] = React.useState(false);
    const btnDropdownRef: RefObject<HTMLButtonElement> = React.createRef();
    const popoverDropdownRef: RefObject<HTMLDivElement> = React.createRef();

    useWindowEventListener("mousedown", (event: { target: any }) => {
        if (btnDropdownRef.current && btnDropdownRef.current.contains(event.target)) {
            return;
        } else if (popoverDropdownRef.current && !popoverDropdownRef.current.contains(event.target)) {
            setDropdownShow(false);
        }
    });
    // bg colors
    let bgColor;
    let position;
    if (color === "default") {
        bgColor = "bg-toolbar-bgMain";
        position = "-left-7 2xl:top-11 top-10";
    } else {
        bgColor = "2xl:w-5 2xl:h-5 w-3 h-3 " + color;
        position = "right-0 2xl:top-10 top-9";
    }
    return (
            <div className="flex flex-wrap px-3 mt-1 ">
                <div className="relative inline-flex align-middle w-full ">
                    <div onClick={() => setDropdownShow(!dropdownShow)} className="flex items-center cursor-pointer ">
                        <button
                                className={
                                        "flex  items-center justify-center tpeer text-xs text-white focus:outline-none rounded-full " +
                                        bgColor
                                }
                                type="button"
                                ref={btnDropdownRef}
                        >
                            {label}
                        </button>
                        {color === "default" ? (
                                <FontAwesomeIcon className="text-white text-xs ml-2" icon={faChevronDown}/>
                        ) : null}
                    </div>

                    {dropdownShow && (
                            <div
                                    // ref={popoverDropdownRef}
                                    className={
                                            "absolute top-10 z-50 bg-dropDown-bgMain py-2 list-none text-left shadow-lg overflow-y-scroll dropdown-hidden-scrollbar min-w-fit 2xl:h-52 h-44 " +
                                            position
                                    }
                                    ref={popoverDropdownRef}
                            >
                                {children}
                            </div>
                    )}
                </div>
            </div>
    );
};

export const DropDownMultipleItem: FC<DropDownMultipleItemProps> = ({onClick, onButtonClick, label, selected}) => {
    return (
            <div onClick={(e) => {
                e.preventDefault();
                onClick();
            }}
                 className={"2xl:text-monitorBase text-monitorSm py-2 px-4 font-normal block w-full whitespace-nowrap " + (selected ? 'bg-gray-600' : '')}
            >
                <label className="flex items-center cursor-pointer">
                    <button className="relative"
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                onButtonClick();
                            }}>
                        {selected ? <FontAwesomeIcon className='text-white' icon={faMinus}/> :
                                <FontAwesomeIcon className='text-white' icon={faPlus}/>}
                    </button>
                    <div className="ml-3 text-white font-bold">{label}</div>
                </label>
            </div>
    );
};