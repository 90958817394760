import {FC, Fragment, useMemo, useState} from "react";
import {
    BookmakerData,
    ExtraMainBookmakers,
    ExtraMarketData,
    ExtraMarketObject,
    ExtraMarketSpreads,
    User
} from "../../../@types/response";
import {useSelector} from "react-redux";
import {ENABLED_BOOKMAKERS} from "../../../constants/ExtraConstants";
import {getBookmakers} from "../../../store/selectors/getBookmakers";
import getClient from "../../../store/selectors/getClient";
import {GetExtraSpreadsCall} from "../../../apis/extra";
import {getExtraMarkets} from "../../../store/selectors/getExtraMarkets";

interface ExtraTableProps {
    eventId: number
    odds: ExtraMainBookmakers
    markets: ExtraMarketObject[]
}

export const ModalExtraTable: FC<ExtraTableProps> = ({eventId, odds, markets}) => {

    const bookmakers: BookmakerData = useSelector(getBookmakers);
    const client: User = useSelector(getClient) as User
    const extraMarkets: ExtraMarketData = useSelector(getExtraMarkets)

    const [spreads, setSpreads] = useState<ExtraMarketSpreads>({});
    const [tooltip, setTooltip] = useState<null | string>(null);
    const [callTimeout, setCallTimeout] = useState<any>(null);
    const [tooltipTimeout, setTooltipTimeout] = useState<any>(null);

    const extraBookmakers: BookmakerData = useMemo(() => {
        return Object.keys(bookmakers)
                .filter(id => ENABLED_BOOKMAKERS.has(parseInt(id)))
                .reduce((a, v) => ({...a, [v]: bookmakers[v]}), {})
    }, [bookmakers])

    const sortedOutcomes: { [id: string]: { id: string, name: string }[] } = useMemo(() => {
        return markets
                .map(m => ({
                    marketId: m.id,
                    outcomes: Object.entries(m.outcomes)
                            .sort((a, b) => (a[1].priority ?? 0) - (b[1].priority ?? 0))
                            .map(([sid, sign]) => ({id: sid, name: sign.name}))
                }))
                .reduce((a, v) => ({...a, [v.marketId]: v.outcomes}), {})
    }, [markets])


    const getOdds = (bookmakerId: number, marketId: string) => {
        GetExtraSpreadsCall(eventId, bookmakerId, marketId).then((result) => {
            setSpreads(result)
        }, () => {
            setSpreads({})
        })
    }

    const showTooltip = (bookmakerId: number, marketId: string, signId: string) => {
        setTooltip(`${bookmakerId}#${marketId}_${signId}`)
    }

    const handleMouseEnter = (bookmakerId: number, marketId: string, signId: string) => {
        if (tooltip) setTooltip(null)
        setCallTimeout(setTimeout(getOdds, 400, bookmakerId, marketId))
        setTooltipTimeout(setTimeout(showTooltip, 500, bookmakerId, marketId, signId))
    }

    function handleMouseLeave() {
        setTooltip(null)
        setSpreads({})
        if (tooltipTimeout) {
            clearTimeout(tooltipTimeout)
            setTooltipTimeout(null)
        }
        if (callTimeout) {
            clearTimeout(callTimeout)
            setCallTimeout(null)
        }
    }

    const eventBookmakers: number[] = useMemo(() => {
        return Object.values(extraBookmakers)
                .sort((a, b) => {
                    if (client.client && a.id === client.client) {
                        return -1
                    } else if (client.client && b.id === client.client) {
                        return 1
                    } else {
                        return (a.name > b.name) ? 1 : -1
                    }
                })
                .map(b => b.id)
    }, [extraBookmakers, client]);

    return <table className='mt-4 border-spacing-0 w-full' key={'event_id'}>
        <thead>
        <tr>
            <th/>
            <th/>
            {markets.map(item => {
                return <Fragment key={item.id}>
                    <th title={item.name} colSpan={Object.keys(item.outcomes || []).length} className='text-center'>
                        <span style={{fontSize: 'smaller'}}>{item.name}</span> <br/>
                    </th>
                    <th style={{minWidth: '10px'}}></th>
                </Fragment>
            })}
        </tr>
        <tr>
            <th style={{width: '125px'}} className={'font-extralight text-sm text-center px-2 whitespace-nowrap'}/>
            <th style={{minWidth: '10px'}} className='bg-eventDetail-primary'></th>
            {markets.map(item => {
                return <Fragment key={item.id}>
                    {sortedOutcomes[item.id]
                            .map((sign) => {
                                return <Fragment key={`${item.id}-${sign.name}`}>
                                    <th title={sign.name} style={{width: '50px'}}
                                        className={'font-extralight text-sm text-center px-1 overflow-hidden'}>
                                        {sign.name}
                                    </th>
                                </Fragment>
                            })}
                    <th style={{minWidth: '10px'}}/>
                </Fragment>
            })}
        </tr>
        </thead>
        <tbody>
        {eventBookmakers.map((order_id) => {
            if (order_id === undefined || !Object.keys(bookmakers).includes(order_id.toString())) return null
            const bookmaker = bookmakers[order_id]
            const bgBook = client.client === bookmaker.id ? 'bg-eventDetail-pinnacle' : 'bg-eventDetail-bookmaker'
            return <tr key={`book-${bookmaker.id}`}
                       className={`${bookmaker.details > 0 ? 'border' : 'border-b'} border-monitor-primary ${bgBook}`}>
                <th style={{height: '32px', width: '125px'}}
                    className={`text-left sticky left-0 z-10 ${bgBook} border-eventDetail-primary`}>
                    {bookmaker.name}
                </th>
                <th style={{minWidth: '10px'}} className={bgBook}></th>
                {markets.map(item => {
                    const market = odds[bookmaker.id]?.[item.id]
                    const main = market?.main ?? '#'
                    const clientMain = client.client ? odds[client.client]?.[item.id]?.main : null
                    return <Fragment key={item.id}>
                        {sortedOutcomes[item.id]
                                .map(({id}) => {
                                    try {
                                        const backOdd = market?.sbv[id].odd;
                                        return <td key={id} style={{width: '50px'}}
                                                   className={`text-center px-1.5`}
                                        >
                                            <b>
                                                {main !== '#' ? <Fragment>
                                                    <i
                                                            className={`
                                                        font-thin 
                                                        ${market?.multiple ? 'text-table-multisbv cursor-pointer' : 'text-table-sbv'}
                                                        ${client.client && clientMain !== main ? 'bg-table-diffsbv' : ''}
                                                    `}
                                                            onMouseEnter={() => {
                                                                if (!market?.multiple) return
                                                                handleMouseEnter(bookmaker.id, item.id, id)
                                                            }}
                                                            onMouseLeave={handleMouseLeave}
                                                    >[{main}]</i>&nbsp;
                                                </Fragment> : null}
                                                <span className='text-eventDetail-secondary'>{backOdd > 1 ? backOdd : '-'}</span>
                                            </b>
                                            {tooltip === `${bookmaker.id}#${item.id}_${id}` ?
                                                    <div className='fixed p-4 z-50 bg-eventDetail-sbv text-white px-4 py-2 rounded-xl font-light text-xs'>
                                                        <table>
                                                            <thead className='bg-eventDetail-primary border-b-2 border-b-eventDetail-sbv'>
                                                            <tr>
                                                                <th className='px-3'>Spread</th>
                                                                {Object
                                                                        .values(extraMarkets[item.id].outcomes)
                                                                        .map((item) => <th
                                                                                className='px-3'>{item.name}</th>)}
                                                            </tr>
                                                            </thead>
                                                            <tbody className='bg-eventDetail-sbv-row'>
                                                            {Object.entries(spreads)
                                                                    .sort((a, b) => parseFloat(b[0]) - parseFloat(a[0]))
                                                                    .map(([spread, odds]) => <tr
                                                                            className='border-b-2 border-b-eventDetail-sbv'>
                                                                        <td className='col-1 text-table-multisbv'>{spread}</td>
                                                                        {sortedOutcomes[item.id]
                                                                                .map(({id}) => <td
                                                                                        className='col-1'>{odds[id]}</td>)}
                                                                    </tr>)}
                                                            </tbody>
                                                        </table>
                                                    </div> : null}
                                        </td>
                                    } catch
                                            (ex) {
                                        return <td style={{width: '50px'}}/>
                                    }
                                })}
                        <td style={{minWidth: '10px'}} className={bgBook}/>
                    </Fragment>
                })}
            </tr>
        })}
        </tbody>
    </table>
}
